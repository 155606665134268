@import "../../../../src/assets/styling.scss";

.table-parent{
  width : 100%;
}
.tableslist {
  z-index: 100;
  color: $fontColorVAlue !important;
  background-color: $bgColorValue !important;
  font-size: $tablepagefontsize !important;
  margin-top: 6.6vh;
}

.tables-list-container {
  width: 100vw !important;
  display: flex !important;
  height : max-content;
  font-size: $tablepagefontsize !important;
  overflow: hidden !important;

  .tabs-container {
    display: flex !important;
    overflow-x: scroll !important;
    width: 100vw !important;
    overflow-x: auto !important;
    padding-left: 24px !important;
    padding-right: 29px !important;
    
    .tabs {
      overflow-x: auto !important;
      overflow-y: hidden !important;
    }
    .add-button {
      width: 80px !important;
      height : 97.5%;
      border: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
  }
}

.custom-box {
  margin-right: 10px !important;

  .filter-box {
    min-width: 100px !important;
    width: auto !important;
    display: flex !important;
    align-items: center !important;
    font-size: $tablepagefontsize !important;
    justify-content: space-between !important;
    color: $filterColorValue !important;
    border-radius: 0 !important;
    padding: 8px !important;
    margin-top: 12px !important;
    margin-bottom: 6px !important;
    margin-right: 2px !important;
    border: 1px solid $filterColorValue !important;
    height: 15.1px !important;
    line-height: 1;
    /* Add this line to ensure the height doesn't expand */
    white-space: nowrap;
    /* Add this line to prevent wrapping */
    overflow: hidden;
    /* Add this line to hide overflowing content */
    text-overflow: ellipsis;
    /* Add this line to show ellipsis when content is truncated */

    &:hover {
      text-decoration: underline !important;
      cursor: pointer !important;
    }

    .MuiIconButton-root {
      color: $filterColorValue !important;
    }
  }
}

.filter-button {
  width: 110px !important;
  margin-top: 2px !important;
  height: 15px !important;
  padding: 8px !important;
  margin-bottom: 6px !important;
  text-transform: none !important;
  font-size: $tablepagefontsize !important;
  &.hover {
    background-color: $responseboxbgcolor;
  }
}

.moreverticon {
  color: $filterColorValue !important;
}

.table-loading {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  
}

.tablesmargin {
  margin-top: 170px !important;
}

.custom-button-add-view {
  margin-top: 12px !important;
  font-size: 16px !important;
  height: 32.6px !important;
}

.tableList-add-view {
  padding: 0 20px 0 24px !important;
  justify-content: left !important;
  flex-wrap: nowrap !important;
  max-width: 100vw !important;
  display: flex !important;
}
.tableList-div-1 {
  display: flex !important;
  flex-direction: row !important;
  height: 8vh !important;
  overflow-y: hidden !important;
}
.tableList-div-2 {
  padding: 0 0 100vh 0 !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  max-width: 89.5vw !important;
 
  display: flex !important;
  flex-direction: row !important;
}
.tableList-div-3 {
  display: flex !important;
  white-space: nowrap !important; 
  text-overflow: ellipsis !important;
}
.tableList-div-4 {
  display: flex !important;
  justify-content: flex-start !important;
}
.tableList-div-5{
  padding: 0 0 0 24px !important;
  display: flex !important;
  justify-content: space-between !important;
}
.tableList-buttons{
  font-size: $tablepagefontsize !important;
  color: $fontColorVAlue !important;
  padding: 0 !important;
  text-transform: none !important; 
}
.delete-color{
  color: $errorcolor !important;
}