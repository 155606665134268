.fieldPop-main-container{
    border-radius: 0!important;
}

.fieldPop-Dialog {
    display: flex !important;
    margin: 16px !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
.field-header{
    margin-bottom: 5%!important;
}
.field-textfield{
    margin-left: 16px!important;
}
.field-close-icon {
    cursor: pointer !important;
  }
.field-textfield2{
    width: 92%!important;
    margin-right: 16px!important;
    margin-left: 16px!important;
}
.field-select-option{
    margin-right: 8px!important;
}
.field-default-input{
    display: flex;
    margin : 10px 0;
    justify-content: left;
    align-items: center;
    input{
        padding : 5px;
        margin-left : 5px;
        height : 1.2rem;
        width: 100%;
    }
}