
.useTemplatePopup{
    display: flex mp !important; 
    margin:16px !important;
    justify-content: space-between !important;
}

.useTemplatePopup-text{
    padding-left: 16px !important;

}

.usetemplatepopup-closeIcon:hover{
    cursor: pointer !important

}

.useTemplatepopup-dialogContent{
    padding: 16px!important;
    padding-left:16px !important;
    padding-right:8px !important;
}

.useTemplatepopup-select{
    border-radius: 0!important;
    margin: 16px !important;
}