$bgColorValue:#fff;
$fontColorVAlue:#000;
$filterColorValue:black;
$responseboxfontColor:#fff;
$responseboxbgcolor:#4A4A4A;
$codeblockbgcolor:#F0F0F0;
$copybuttoncolorlight:#fff;
$copybuttoncolordark:#0000;
$googleauthbordercolor:#0000;
$tablepagefontsize:1rem;
$errorcolor:#a51226;
$selectedtabfontcolor:white;
$selectedtabbgcolor:black;
$editfilterbutttonsfontsize:12px;
$megatitlesize:30;
$mainnavbartextcolor:$fontColorVAlue;
$fontFamily:'Inter';
$mainnavbarfontweight:200;
$optionalparametercontentwidth:60vw;
$linecolor:#ccc;
$textsize:18px;
$mainnavbartabsfont:1rem;
$codeblockfontsize:20px;
$bold:bold;
$highlightedtabbgcolor:lightgrey;
$titlefontsize:20px;
:export {
  bgcolorvalue:$bgColorValue;
  titlevariant:h2;
  deletecolor:$errorcolor;
titleweight:bold;
  megatitlevariant:h3;
    megatitlesize: $megatitlesize;
    titlesize:24;
    titlefontsize:$titlefontsize;
    textsize:18;
    tablepagefontsize:$tablepagefontsize;
    basictextcolor:$fontColorVAlue;
mainnavbartextcolor:$mainnavbartextcolor;
fontFamily:Inter;
mainnavbarfontweight:$mainnavbarfontweight;
homebuttonvariant:h6;
highlightedtabbgcolor:$highlightedtabbgcolor;
profileiconheight:34px;
codeblockbgcolor:$codeblockbgcolor;
profileiconwidth:36px;
codeblockcopybuttonsize:20px;
linecolor:$linecolor;
linksizeoptionalparameter:14;
highlightedfilterboxcolor: #9EA9B7;
filterboxcolor:#E6E6E6;
optionalparametercontentwidth:$optionalparametercontentwidth;
editfilterbutttonsfontsize:$editfilterbutttonsfontsize;
headermenufontsize:13px;
landingpagetitlevariant:h5;
iconfontsize1:2px;
rowHoverColor:#f7f7f7;
iconfontsize2:4px;
somebiggertextvariant:h5;
  }