@import "../../../../assets/styling.scss";
* {
  font-family: "Inter" !important;
}

.optional-parameter-container {
  border-top: 0.1px solid $linecolor !important;

  border-radius: 0px !important;
  padding: 10px !important;
  padding-left: 0px !important;
  width: 60vw !important;
  background-color: $bgColorValue !important;
  padding-bottom: 20px !important;
}

.bold-heading {
  font-weight: bold !important;
  font-size: 17px !important;
}
.titlepaddingtop {
  padding-top: 24px !important;
}

.parameter-heading {
  font-weight: bold !important;
  font-size: 17px !important;
  padding-top: 10px !important;
}

.code-in-text {
  width: $optionalparametercontentwidth !important;
  height: 15vh !important;
  background-color: $codeblockbgcolor !important; /* Use the variable for background color */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 32px !important;
}
.parameter-description {
  padding-bottom: 15px !important;
}

.selectbox {
  height: 50px !important;
  width: 200px !important;
  border-radius: 0 !important;
  height: 50px !important;
  width: 200 !important;
  border-radius: 0 !important;
  color: $fontColorVAlue !important;
}
.menu-item-1 {
  border-bottom: 1px solid lightblue !important;
  color: $fontColorVAlue !important;
}
.text-field {
  height: 40px !important;
  width: 100% !important; /* Use 100% width to occupy the full available space */
}
.blackcolor {
  color: $fontColorVAlue !important;
}
.flexandcenter {
  display: flex !important;
  align-items: center !important;
}
.link {
  font-size: 15px !important;
}

.limit-heading {
  font-weight: bold !important;
  font-size: 17px !important;
  padding-bottom: 8px !important;
  padding-top: 13px !important;
}

.limit-description {
  font-weight: bold !important;
  font-size: 17px !important;
  padding-bottom: 40px !important;
}

.hoverable-cell {
  position: relative;
}
.colorblack {
  color: $fontColorVAlue !important;
}
.margin1 {
  margin: 8px !important;
}
.ascanddesc {
  height: 50px !important;
  width: 100px !important;
  border-radius: 0px !important;
  color: $fontColorVAlue !important;
}
.optionalparameterpara {
  word-wrap: pre-wrap !important;
  width: 60vw !important;
  padding: 16px !important;
}
.mt0 {
  margin-top: 0 !important;
}
.aifilterbox {
  display: flex !important;
  justify-content: center !important;
  padding: 8px !important;
}
.marginandminwidth {
  margin: 8px !important;
  min-width: 200px !important;
}

.hoverable-cell .add-button {
  display: none;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.hoverable-cell:hover .add-button {
  display: inline-block;
}

//filter condition talbe css

.filter-condition-main-table {
  width: 80% !important;
  border-collapse: collapse !important;
}

.filter-condition-table-head-table-cell {
  padding: 8px !important;
  border: 1px solid #000 !important;
  background-color: #f0f0f0 !important;
}

.filter-condition-table-body-table-row {
  border: 1px solid #000 !important;
}

.filter-condition-table-body-table-row-cell {
  padding: 8px !important;
  border: 1px solid #000 !important;
}

.ilter-condition-div {
  display: flex !important;
  align-items: center !important;
}

.row-example {
  margin-left: 8px !important;
}

.table-body-show-more {
  color: #016fa4 !important;
}
.select-2 {
  height: 50px !important;
  width: 100% !important;
  border-radius: 0 !important;
  color: $fontColorVAlue !important;
}
.menu-item-2 {
  color: $fontColorVAlue !important;
}
.select-3 {
  height: 50px !important;
  width: 100px !important;
  border-radius: 0 !important;
  color: $fontColorVAlue !important;
}
.text-field-1{
  height: 50px !important;
  width: 100px !important;
  color: $fontColorVAlue !important;
}