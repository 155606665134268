@import '../../../../assets/styling.scss';
.response-box{
  
  color: $responseboxfontColor !important;
  background-color: $responseboxbgcolor!important;
  width: 35vw !important;
  height: 100% !important;
  margin-bottom: 12px !important;
  font-size:$codeblockfontsize !important;

  margin-top: 10px !important;
  padding-bottom: 1vh !important;
}
.numbercolor{
  color:#013220 !important;
}
.stringcolor{
  color:#7a2048 !important; 
}
.codeblock-header{
  display: flex !important;
  flex-direction: row-reverse !important;
  height: 30px !important;
  padding-right: 15px !important;
  background-color: $codeblockbgcolor !important;
}

.code-block {
  background-color: $codeblockbgcolor !important;
  color: $fontColorVAlue !important;
  padding-bottom: 1vh !important;
  overflow-y:visible !important;
   
    width:35vw  !important;
   
   height: fit-content !important;
  margin-top: 30px !important;
  font-size:$codeblockfontsize !important;
}

  .valuescolor{
    color:#028A0F !important;
  }
 
  
  .copy-button {



    background-color: transparent !important;
    color: black !important;
    border: none !important;
    border-radius: 0 !important;
    margin-right: -14px !important;
     padding: 4px 10px !important; 
    cursor: pointer !important;
  }
  
  .copy-button:hover {
    opacity: 0.8 !important;
  }
  .code-block code {
    white-space: pre-wrap !important;
  }
  
  .button {
    position: relative !important;
    bottom: 59px  !important;
    font-size: 15px  !important;
  }
  
  .button-api {
    background-color: $fontColorVAlue;
    color: $bgColorValue;
    font-size: 18px !important;
  }
  .button-curl {
   
    font-size: 18px !important;
    background-color: $bgColorValue  !important;
    color: $fontColorVAlue  !important;

  }
  .response-header{
    height: 26px !important;

    background-color: $fontColorVAlue  !important;
  }
  .response-body{
    padding: 10px !important;
    padding-left: 2.5vw !important;
  }
  .pre-wrapper {
    position: relative !important;
    width: 32vw !important;
    height:auto !important;
    padding-left: 1.6vw !important;
    
    white-space: pre-wrap !important;
  }
  
  .code {
    white-space: pre-wrap !important;
    max-width: 32vw !important;
    word-wrap: break-word !important;
  }
  
  .yellow {
    color: yellow !important;
  }
  
  .blue {
    color: #3D5A80 !important;
  }
  
  .cyan {
    color: cyan !important;
    margin: 1px !important;
  }
  
  .green {
    color: green !important;
    margin: 1px !important;
  }
  
  .magenta {
    color: magenta !important;
    margin: 1px !important;
  }
  .active{
    cursor:  pointer !important;
      }