.authKey-container {
  margin: 8px 0px !important;
  padding: 0 31px 0 24px !important;
}
.authkey-table-container {
  width: 100% !important;
  max-height: 60vh !important;
  border: 1px solid black !important;
  border-radius: 0 !important;
}
.auth-key-table {
  min-width: 650px !important;
  overflow-y: scroll !important;
}
