@import '../../../../assets/styling.scss';

.basic-stuff-container {
  display: flex !important;
  flex-direction: column !important;
  
  justify-content: center !important;
}
.bold-text {
  font-weight: $bold !important;
}

.field-name-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 10px; /* Add margin bottom for spacing between field names */
}

.field-name {
  font-size: 20px;
}

.field-id-container {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center !important;
  margin-bottom: 10px; /* Add margin bottom for spacing between field IDs */
  position: relative; /* Add position relative to create a stacking context */

  .copied-text {
    position: absolute; /* Position the copied text relative to the field ID container */
    right: -5% !important ; /* Position the copied text to the left of the field ID */
    top: 50%; /* Position the copied text vertically in the middle */
    transform: translateY(-50%); /* Adjust the vertical positioning */
    font-size: 12px;
    color: $fontColorVAlue;
  }
}


span {
  padding: 0 !important;
  margin: 0 !important;
}
.horizontalscroll{
  overflow-x: scroll !important;
}
.basicstuffpara1{
  word-wrap: pre-wrap !important;
  width: 60vw !important;
  padding: 16px !important;
}

.basicstufftitle2{
  border-top: 0.2px solid $linecolor;
  width:60vw !important;
}

.basicstuffIdsOnTop{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  font-size: 18px !important;
  padding-left: 32px !important;
  .ids{
    color:#016FA4 !important
  }
}
.basicstuffdiv1{
  display: flex !important;
  justify-content: center !important;
  padding: 16px !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  width: 60vw !important;
}
.basicstuffrecordstypography{
  word-wrap: pre-wrap !important;
  width: $optionalparametercontentwidth !important;
  padding: 16px !important;
}
.copy-button1 {
  transition: background-color 0.3s ease !important;
  background-color: transparent !important;
  color: $fontColorVAlue !important;
  margin: 0px 5px !important;
  border: none !important;
  opacity: 0.5;
  font-size: 3px  !important; /* Increase font size for better visibility */
  padding: 0 !important;
  display: flex; /* Add display flex for aligning icon and copied text */
  align-items: center; /* Align icon and copied text vertically */
  cursor: pointer; /* Add cursor pointer to indicate interactivity */
}

.color-black {
  color: $fontColorVAlue;
  font-size: 15px !important;
}

.copied-text {
  font-size: 12px;
  color: $fontColorVAlue;
  margin-left: 5px; /* Add margin left for spacing between copied text and copy button */
}
.left{
  text-align: left !important;
}
.right{
  text-align: right !important;
}