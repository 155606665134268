.dbduplicate-container{
    margin-bottom: 5% !important;

}
.dbduplicate-typography{
    margin-left: 16px !important;
}
.dbduplicate-close-icon {
    &:hover {
      cursor: pointer !important;
    }
  }
.dbduplicate-dailog-text{
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 0;
    padding-right: 8px;
    padding-left: 16px;
    
}
.duplicat-button{
    display: flex;
    margin: 16px;
    margin-top: 0px;
    justify-content: space-between;
}