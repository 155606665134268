.flex-center-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.authPageMainContainer{
    background-color: #E8E8E8 !important;
    min-width: 100vw;
    max-width: fit-content;
    min-height: 100vh;
    max-height: fit-content;
    overflow: scroll;
}

.authContainer2{
    background-color: white !important;
    min-width: 27% !important;
    max-width: fit-content !important;
    min-height: 80% !important;
    max-height: fit-content !important;
    box-shadow: 3 !important;
    border-radius: 5px ;
    padding: 16px;
}

.bottomLine{
    color: blueviolet;
    cursor: pointer;
    text-decoration: underline;
}
.flex-col-center-center {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.back-to-login{
    height : 100vh;
    & *{
        cursor: pointer;
    }
}