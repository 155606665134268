@import '../assets/styling.scss';
.carousel-container {
   display: flex !important;
   flex-direction: row !important;
   overflow-x: scroll !important;
   scroll-snap-type: x mandatory !important;
 }
 .transparent-blur-background {
   color:$errorcolor  !important;
   position: absolute !important;
   bottom:18px !important;
   right: 50% !important;
   transform:translateX(50%);
   z-index: 1 !important;
   display: flex !important;
   align-items: center !important;
   font-size: $tablepagefontsize !important;
   background-color: #fffa!important;
   border: 1px solid black;
   padding: 1px 5px !important;
   border-radius: 2.5px !important;

 }
 .transparent-blur-background:hover{
  background-color:$errorcolor !important;
  color: #fff !important;
 }
 
 .carousel-image {
  //  flex: 0 0 80% !important;
   height : 90%;
   scroll-snap-align: start !important;
   transition: transform 0.3s ease !important;
 }
 
 .carousel-image.active {
   transform: translateX(0%) !important;
 }
 
 .modal-button-container {
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
 }
 
 .modal-button-container button {
   margin: 0 5px !important;
 }
 

 
 @media screen and (max-width: 600px) {
   .carousel-container {
     flex-wrap: nowrap !important;
     scroll-snap-type: x mandatory !important;
   }
 
  
 
   .carousel-image {
     flex: 0 0 100% !important;
   }
 }
