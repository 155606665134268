.duplicatefield-container{
    position: fixed!important;
    top: 0!important;
    left: 0!important;
    width: 100%!important;
    height: 100%!important;
}
.duplicatefield-div2{
    background-color: #fff!important;
    padding: 20px!important;
    width: 400px!important;
    position: sticky!important;
    top: 50%!important;
    transform: translateY(-50%)!important;
    border: 2px solid black!important;

}
.duplicatefield-button-box{
    display: flex!important;
    justify-content: flex-end!important;
    margin-top: 20px!important;
}
