  .popupModalTitle {
    margin-left: 8px !important;
  }
  
  .closeIcon {
    cursor: pointer !important;
  }
  
  .popupModalContent {
    margin: 16px!important;
  }
  
  .inputField {
    margin-bottom: 16px !important;
  }
  
  .textField {
    width: 100% !important;
  }
  
  
  .templateOption {
    margin: 16px !important;
  }
  
  .buttonContainer {
    display: flex !important;
    justify-content: space-between !important;
    margin: 16px !important;
  }
  

  
  
  
