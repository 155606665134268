@import './assets/styling.scss';
body{
  background-color: $bgColorValue;
  color:$fontColorVAlue;
  font-family: 'Inter' !important;
}
Button{
  border-radius:0 !important;
 text-transform: none !important;


}
.leftsidepartofapidoctabs{
  width: 60vw !important;
  overflow-x: hidden !important;
}
Tab{
 text-transform: none !important;

}
.fontsize{
  font-size: $tablepagefontsize !important;
}
.mui-button{
  background-color: $fontColorVAlue !important;
  color:white !important;
}
.mui-button-disabled{
  background-color: $fontColorVAlue !important;
  color:white !important;
  opacity: 0.4 !important;
}
.mui-button-outlined{
  color:$fontColorVAlue !important;
  border-color:$fontColorVAlue !important;
  text-transform: none !important;
}
 .deletecolor{
   color:$errorcolor !important;
 }
.Mui-selected  {
  background-color: $fontColorVAlue !important;
  color: $bgColorValue !important;
  text-transform: none !important;

}
.deleterows{
  margin-top: 5px !important;
color:$errorcolor !important;
}
.popupheader{
  height: 4vh !important;
  background-color: $codeblockbgcolor !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: '2' !important;
  align-items: center !important;

}
.errorcolor{
  color:$errorcolor !important;
}
.paddingtopoftitle{
  padding-top: 24px !important;
}
.divofapidoccodetypeforurl{
  width:$optionalparametercontentwidth !important;
  height: 15vh !important;
  background-color: $codeblockbgcolor !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.typographyinsidecodestyle{
  justify-content: center !important;
  width: 100% !important;
  padding-left: 16px !important;
}
.flex-center{
  display: flex !important;
  align-items: center !important;
}