.bold-heading {
  font-weight: bold !important;
}

.field-name {
  font-size: 18px !important;
}

.field-id {
  font-size: 18px !important;
}
.recordcontainer{
  padding: 16px 0px !important;

}
.border-basicstuff{
  border-top: 0.1px solid #ccc !important;
  border-bottom: 0.1px solid #ccc !important;
  width:60vw !important;

}

.field-type {
  font-size: 18px !important;
}

.add-remove {
  padding-bottom: 6px !important;
}

.add-remove-button {
  font-size: 18.4px !important;
  background-color: transparent !important;
  border: none !important;
  display: inline-block !important;
  vertical-align: middle !important; /* Add this line to align checkboxes vertically */
}
.center-aligned{
  text-align: center !important;
}