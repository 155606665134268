#root >.MuiContainer-root {
padding : 0;
}
.landingpagemaincontainer{
    overflow-x: hidden !important;
    height: 100vh !important;
}

.landingpagegrid{
    margin-left: 8px !important;
}
.deletedbtitle{
    margin-left: 24px !important;
}
.boxfordeleteddbs{
    display: flex !important;
    margin :32px !important;
    margin-left: 16px !important;

}
.landingpageloader{
    position: absolute;
    left: 50%;
    top : 50%;
    transform: translate(-50%, -50%);
}