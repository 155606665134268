.row-parent {
  margin: 2px 0;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: min(100rem, 100%);
  border-radius: 10px;
  background-color: whitesmoke;
  .row-heading{
    font-size : 1.2rem;
    font-weight : bolder;
    color : #495057;
  }
  .row {
    width: 100%;
    max-width: calc(100% - 1rem);
    overflow: auto;
    padding: 5px;
    display: flex;
    span {
      background-color: #e0eafe;
    }
    .row-fields {
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      white-space: nowrap;
      .row-field {
        text-transform : uppercase;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size : 0.8rem;
        // font-weight : bold;
        color : #91999f;
      }
      .row-data{
        font-size : 1.2rem;
        font-weight : bold;
        color : #6c757d;
        max-width : 5in;
        text-overflow : ellipsis;
        overflow : hidden;
      }
    }
  }
  .row::-webkit-scrollbar {
    width: 20%;
    height: 5px;
    background-color: #fff;
  }
  .row::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-image: transparent
  }
}
.row-parent:hover{
  .row::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-image: -webkit-linear-gradient(
      90deg,
      rgb(159, 153, 153, 0.5) 0%,
      rgb(154, 147, 147, 0.75) 25%,
      transparent 100%,
      rgba(131, 109, 109, 0.5) 75%,
      transparent
    );
  }
}