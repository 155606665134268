.auth-key-page-container {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    margin-top: 9vh;
  }
  
  .auth-key-page-button {
    text-decoration: none;
    margin-right: 5px;
  }
  
  .auth-key-page-navbar {
    margin-bottom: 1rem;
  }
  
  .auth-key-page-content {
    margin-top: 2rem;
  }
  