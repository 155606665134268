@import '../../assets/styling.scss';
.main-navbar-container {
  background-color: $bgColorValue !important;
  border-bottom: 0.1px solid $linecolor !important;
  height: 6.5vh !important;
  position: fixed !important;
  top: 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  z-index:150 !important;
}

.main-navbar-link {
  text-decoration: none !important;
  margin-bottom: 8px !important;
  font-family: $fontFamily !important;
  font-size: $mainnavbartabsfont !important;
  color: $fontColorVAlue !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.main-navbar-title {
  &:hover {
    text-decoration: underline !important;
  }
}

.main-navbar-button {
  font-family: $fontFamily !important;
  font-size: $mainnavbartabsfont !important;
  text-decoration: none !important;
  color: $fontColorVAlue !important;
  border-radius: 0 !important;
  border: none !important;
  text-transform: none !important;
  margin-bottom: 2px !important;

  &:hover {
    border: none !important;
    background-color: transparent !important;
    color: $fontColorVAlue !important;
    text-decoration: underline !important;
  }
}
.show-table{
  pointer-events: none !important;
  background-color: $highlightedtabbgcolor !important;
}

.main-navbar-avatar-button {
  border-radius: 0 !important;
  margin-left: 30px !important;
}

.main-navbar-menu {
  margin-top: 45px !important;
}

.text-align-center {
  text-align: center !important;
}
