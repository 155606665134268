/* COMMON CLASSES FOR BOTH GROUPED AND SIMPLE AUTOSUGGEST */
.suggestionBox::-webkit-scrollbar {
    display: none;
}

.editable-div::-webkit-scrollbar {
    display: none;
}

.suggestionBox {
    display: absolute;
    width: auto;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 70vh;
    height: auto;
    overflow: auto;
}

.editable-div {
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 0px;
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    height:'fit-content' !important;
    margin-bottom: 2px;
    padding: 5px;
    word-wrap: break-word;
    overflow: scroll;
}
.editable-div:empty:not(:focus):before {
	content: attr(data-text);
  color: #999999;
}

.chip {
    display: inline-block;
    padding: 2px 10px;
    background-color: #f1f1f1;
    border-radius: 3px;
    font-family: Arial, sans-serif;
    font-size: 13px;
    margin: 2px 3px;
    color: #555;
}

.suggestionMainContainer {
    width: 100%;
    height: 100%;
}

.hoverValueContainer {
    min-width: 100px !important;
    max-width: 200px !important;
    padding: 10px !important;
    word-wrap: break-word;
    color: white;
    background-color: rgb(31, 32, 30);
    font-size: 14px !important;
    border-radius: 5px;
    z-index: 30;
    min-height: 20px !important;
    max-height: fit-content !important;
}

.hoverValueContainer::-webkit-scrollbar {
    display: none !important;
}

/* SIMPLE AUTO-SUGGESTIONS CLASSES */
.listSuggestionContainerForSimpleAutosuggest {
    list-style-type: none;
    font-size: 16px;
    padding: 5px;
}

.listSuggestionContainerForSimpleAutosuggest:hover {
    background-color: rgb(128, 128, 128, 0.5);
}

.listSuggestionTag{
    font-size: 16px !important;
}

.listSuggestionTagContent{
    font-size: 12px !important;
}

/* GROUPED SUGGESTIONS CLASSES */
.listSuggestions {
    list-style-type: none;
    font-size: 16px !important;
    padding: 0px 0px 0px 17px;
}

.valueContent {
    font-size: 12px !important;
    list-style-type: none;
    margin: 0px 0px 0px 17px;
}

.ulTag {
    font-size: 21px !important;
    padding: 5px;
    background-color: black;
    color: white;
    width: 100% !important;
}

.listSuggestionContainer {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.listSuggestionContainer:hover {
    background-color: rgb(128, 128, 128, 0.5);
}