.workspacecombinedbox1{
    display: flex !important;
    justify-content:space-between !important ;
    margin-top: 8.5vh !important;
    width: 100% !important;
}
.createorgbutton{
    margin-left: 24px !important;

}
.exploretemplatebutton{
    margin-right: 30px !important;
}