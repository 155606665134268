.edited-item{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    .edited-div{
        display: flex;
        width: 100%;

    }
    .revert-button{
        margin-top : 30px;
    }
    .edited-by{
        display:flex;
        justify-content: space-between;
        .edited-name{
            margin-right: 30px;
        }
    }
}
.row-history-dialog{
    padding: 10px;
    width: 62rem;
    margin: auto;
}

.row-history{
    min-width: 35rem;
    min-height: 10rem;
    height: 31rem;
    width: auto;
    display: flex;
    justify-content: center;
    padding : 0 10px;
    .no-history{
        margin: 30px 0;
    }
}
.row-history-header{
    display: flex;
    justify-content: space-between;
    .close-button:hover{
        background-color: transparent;
    }
}
.field-selector{
    display: flex;
    justify-content: center;
    align-items: center;
    .field-dropdown{
        margin-left: 10px;
    }
}