
.delete-record-container {
  display: flex !important ;
  flex-direction: column !important ;
  position: fixed !important ;
  right: 0 !important ;
  width: 36vw !important;

  padding: 10px !important ;
  padding-right: 13px !important;
  overflow-x: hidden !important;
    overflow-y: scroll !important;
  white-space: pre-wrap !important ;
}
 
  .records-container {
    width: 62vw !important;
    white-space: pre-wrap !important;
    padding: 2px !important;
    overflow-x: hidden !important;
    overflow-y: hidden ;
padding-right: 140%  !important;

 
  }
  .updaterecordfirstpara{
    word-wrap: pre-wrap !important;
    width: 60vw !important;
    padding: 16px !important;
  }
  
  .bold-heading {
    font-weight: bold !important;
    font-size: 24px !important;
  }
  