.addoption-container{
    max-height: 300px!important;
    overflow-y: auto!important;
}
.addOption-textfield{
    display: flex!important;
    align-items: center!important;
}
.addOption-cancel{
    cursor: pointer;
    margin-left: 8px;
}

.addOption-addbox{
    display: flex!important;
    justify-content: flex-end!important;
}