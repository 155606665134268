.import-csv-div{
    max-width: 90vw;
    position: relative;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    max-height: 80vh;
    section{
      max-height: 50vh;
      overflow: auto;
    }
  }